import React from "react";
import { css } from "@emotion/css";
import enLocale from "date-fns/locale/en-US";
import CssBaseline from "@mui/material/CssBaseline";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import PublicHome from "./public-home/PublicHome";


const appStyle = css({
    height: "100%",
});

const theme = createTheme({
    typography: {
        fontFamily: "Open Sans, Medium",
    },
});

function App() {
    return (
        <div className={appStyle}>
            <CssBaseline />
            <ThemeProvider theme={theme}>
                <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={enLocale}
                >
                    <Router>
                        <Routes>
                            <Route
                                path="/"
                                element={<PublicHome />}
                            />
                        </Routes>
                    </Router>

                    <ToastContainer
                        position="bottom-left"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </LocalizationProvider>
            </ThemeProvider>
        </div>
    );
}

export default App;
