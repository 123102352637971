import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Button, Card, CardContent, Grid } from "@mui/material";

import design_heading from "../assets/site/landing/design_heading.webp";
import { ContactUs } from "./components/ContactUs";
import { Header } from "./components/Header";
import { BackgroundImg } from "./components/BackgroundImg";

import { ContentText, MediaImage, SectionHeading } from "../common/typography";
import { ContentList } from "../common/typography";

const WorkshopBenefit = () => (
    <Grid item xs={12}>
        <Container maxWidth="md" sx={{ mt: 3 }}>
            <Card sx={{ minWidth: 275 }}>
                <CardContent style={{ textAlign: "center" }}>
                    <SectionHeading sx={{ marginBottom: 0 }} color="#D69129">
                        वर्कशॉप का महत्व
                    </SectionHeading>

                    <MediaImage
                        src={design_heading}
                        sx={{
                            width: { xs: 230, md: 336 },
                            height: { xs: 26, md: 35 },
                            marginBottom: "15px",
                        }}
                    />

                    <Box
                        sx={{
                            width: {
                                xs: "auto",
                                lg: "700px",
                            },
                            margin: "auto",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            rowGap: "20px",
                        }}
                    >
                        <ContentText>
                            आज के प्रगतशील युवा पिढी को दुनिया मे आगे बढने हेतू
                            एवं खुद विकसित होने के लिये ३ मुल्यो की बेहद जरुरत
                            है , वे हैं -
                        </ContentText>

                        <Box
                            sx={{
                                display: "flex",
                                marginBottom: "15px",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    rowGap: "5px",
                                    paddingLeft: "30px",
                                }}
                            >
                                <ContentText
                                    sx={{
                                        marginBottom: 0,
                                        color: "#7873f5",
                                        fontWeight: "500",
                                    }}
                                >
                                    १) सेल्फ डेव्हलपमेंट
                                </ContentText>
                                <ContentText
                                    sx={{
                                        marginBottom: 0,
                                        color: "#ec77ab",
                                        fontWeight: "500",
                                    }}
                                >
                                    २) मनःशांती
                                </ContentText>
                                <ContentText
                                    sx={{
                                        marginBottom: 0,
                                        color: "#ECA1FE",
                                        fontWeight: "500",
                                    }}
                                >
                                    ३) आनंदी जीवन
                                </ContentText>
                            </Box>
                        </Box>

                        <ContentText>
                            वर्कशॉप के माध्यम से, हमारे जीवन के प्रेरणास्थान,
                            प. पू. कानिफनाथ महाराज आज की युवा पिढी को इन विषयो पर
                            मार्गदर्शन करते हैं| एक शिक्षित एवं अध्यात्मिक
                            मूल्यो से विकसित युवा इस दुनिया के लिये जरूर एक दुआ
                            बन सकता हैं, यह बात आज की जीवन शैली को देखकर
                            प्रमाणित होती हैं|
                        </ContentText>
                    </Box>
                </CardContent>
            </Card>
        </Container>
    </Grid>
);

const SelfDevelopment = () => (
    <Grid item xs={12}>
        <Container maxWidth="md">
            <Card sx={{ minWidth: 275 }}>
                <CardContent style={{ textAlign: "center" }}>
                    <SectionHeading
                        sx={{
                            marginBottom: 0,
                            fontSize: "22px",
                            paddingBottom: "15px",
                        }}
                        color="#7873f5"
                    >
                        १) सेल्फ डेव्हलपमेंट
                    </SectionHeading>

                    <Box
                        sx={{
                            width: {
                                xs: "auto",
                                lg: "700px",
                            },
                            margin: "auto",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            rowGap: "20px",
                        }}
                    >
                        <ContentText>
                            खुद के अंदर सुधार का मतलब हैं की जीवन के हर एक पैलू
                            को बेहतर बनाना| अपनी आज की स्थिती , ज्ञान ओर जानकरी
                            को विकसित करने पर अपने आत्मा का विकास जरूर होता है
                            जिस हम सेल्फ डेव्हलपमेंट कहते हैं| इससे व्यक्ती अपने
                            जीवन मे सचेत एवं समझदार बनता है| एक आत्म विकसित
                            व्यक्ती अपने प्रभावी शैली से जीवन का वर्तन
                            परिणामकारक बना सकता हैं| आत्म विकसित होने हेतू मन
                            शांत एवं जीवन आनंदी रहना जरुरी है|
                        </ContentText>
                    </Box>
                </CardContent>
            </Card>
        </Container>
    </Grid>
);

const InnerPeace = () => (
    <Grid item xs={12}>
        <Container maxWidth="md">
            <Card sx={{ minWidth: 275 }}>
                <CardContent style={{ textAlign: "center" }}>
                    <SectionHeading
                        sx={{
                            marginBottom: 0,
                            fontSize: "22px",
                            paddingBottom: "15px",
                        }}
                        color="#ec77ab"
                    >
                        २) मनःशांती
                    </SectionHeading>

                    <Box
                        sx={{
                            width: {
                                xs: "auto",
                                lg: "700px",
                            },
                            margin: "auto",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            rowGap: "20px",
                        }}
                    >
                        <ContentText>
                            जीवन के किसी भी मोड पर सफलता प्राप्त करने हेतू , मन
                            शांत रहना बेहद जरुरी है| शांत मन से किये हुये कार्य
                            ही परिणामकारक राहते हैं| एक शांत मन ही अपने कामों
                            पर पूर्ण लक्ष्य केंद्रित कर, अपनी सर्वश्रेष्ठ
                            उपलब्धी हासिल कर सकता हैं| एक आनंदी एवं शांत मन से
                            कीये गये कार्य की गुणवत्ता सबसे बेहतरीन होती हैं|
                        </ContentText>
                    </Box>
                </CardContent>
            </Card>
        </Container>
    </Grid>
);

const Happiness = () => (
    <Grid item xs={12}>
        <Container maxWidth="md" sx={{ mb: 2 }}>
            <Card sx={{ minWidth: 275 }}>
                <CardContent style={{ textAlign: "center" }}>
                    <SectionHeading
                        sx={{
                            marginBottom: 0,
                            fontSize: "22px",
                            paddingBottom: "15px",
                        }}
                        color="#ECA1FE"
                    >
                        ३) आनंद
                    </SectionHeading>

                    <Box
                        sx={{
                            width: {
                                xs: "auto",
                                lg: "700px",
                            },
                            margin: "auto",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            rowGap: "20px",
                        }}
                    >
                        <ContentText>
                            आनंद जीवन की ऐसी एक स्थिती हैं की, जीसकी अनुभूती हर
                            पल ली जा सकाती हैं| आनंद ये हमारे अस्थित्व की
                            अहंकार-शून्य अवस्था हैं| आनंद हमे अपने जीवन मे
                            अपेक्षित मुकाम तक पहुचाता हैं| आनंद ये हमारे मन की
                            नैसर्गिक स्थिती हैं, जो की हमारे भाव का शुद्ध स्वरूप
                            हैं|
                        </ContentText>
                    </Box>
                </CardContent>
            </Card>
        </Container>
    </Grid>
);

const WorkshopFormat = () => (
    <Grid item xs={12}>
        <Container maxWidth="md">
            <Box sx={{ textAlign: "center" }}>
                <MediaImage
                    src={design_heading}
                    sx={{
                        width: { xs: 230, md: 275 },
                        height: { xs: 26, md: 35 },
                    }}
                />
                <SectionHeading sx={{ marginBottom: 0 }} color="#D69129">
                    वर्कशॉप का स्वरूप
                </SectionHeading>
            </Box>
            <Card sx={{ minWidth: 275 }}>
                <CardContent>
                    <ContentList
                        color="#5c88c0"
                        textItems={[
                            "वर्कशॉप के सत्र ऑनलाईन माध्यम से, zoom मीटिंग द्वारा आयोजित किए जाते हैं|",
                            "एक सत्र मे कुल ४ भाग होते हैं, जिसमे की हर एक भाग के पश्चात १०-१५ दिन का विराम दिया जाता है|",
                            "यह वर्कशॉप विशेष रूप से उम्र २०-३८ साल के युवाओ के लिये प्रस्तुत होता है|",
                            "यह सत्र ऑनलाईन प्रस्तुत होने के कारण, पात्र व्यक्ती इस सत्र मे अपने घर से ही भाग ले सकता है|",
                            <Box>
                                <ContentText color="#5c88c0">
                                    वर्कशॉप मे जुडने के लिये नीचे दिये गये लिंक
                                    पर रजिस्टर करे -
                                </ContentText>
                                <Button
                                    variant="contained"
                                    target="_blank"
                                    sx={{
                                        borderRadius: 15
                                    }}
                                    href="https://eonlineworkshop.com/reg"
                                >
                                    रजिस्ट्रेशन लिंक 
                                </Button>
                            </Box>,
                        ]}
                    />
                </CardContent>
            </Card>
        </Container>
    </Grid>
);

const MoreInfo = () => (
    <Grid item xs={12}>
        <Container maxWidth="md" sx={{ mb: 4 }}>
            <Card sx={{ minWidth: 275 }}>
                <CardContent>
                    <ContentText color="#434343" sx={{ textAlign: "center" }}>
                        रजिस्ट्रेशन पूर्ण होने के पश्चात सभी पंजिकृत सदस्यो को
                        व्हॉट्स ॲप पर अधिकृत ग्रूप की लिंक शेअर की जायेगी|
                    </ContentText>
                    <ContentText color="#434343" sx={{ textAlign: "center" }}>
                        लिंक पे क्लिक कर आप हमारे अधिकृत ग्रूप के सदस्य बन सकते
                        हो|
                    </ContentText>
                    <ContentText color="#434343" sx={{ textAlign: "center" }}>
                        अधिक सूचना एवं नियम ग्रूप मे शेअर कीये जायेंगे|
                    </ContentText>
                </CardContent>
            </Card>
        </Container>
    </Grid>
);

const LandingPage = () => {
    return (
        <div>
            <Box>
                <Header />
                <div>            
                    <Grid container spacing={3}>
                        <WorkshopBenefit />
                        <SelfDevelopment />
                        <InnerPeace />
                        <Happiness />
                        <WorkshopFormat />
                        <MoreInfo />
                        <ContactUs />
                    </Grid>
                </div>
            </Box>

            <BackgroundImg />
        </div>
    );
};

export default LandingPage;
