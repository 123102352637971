import React from "react";
import { css } from "@emotion/css";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Button, Grid, Typography } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

import design_heading from "../../assets/site/landing/design_heading.webp";
import phone_icon from "../../assets/site/landing/phone.png";
import mail_icon from "../../assets/site/landing/mail.png";
import image_instagram from "../../assets/site/image_instagram.png";
import image_linkedin from "../../assets/site/linkedin.png";

import { MediaImage, SectionHeading } from "../../common/typography";

const supportTextStyle = css({
    color: "#48255D",
    fontStyle: "Open Sans, Medium",
    fontSize: "16px",
});

const contactBtnStyle = {
    textTransform: "none",
    background: "#90e7ff",
    width: "234px",
    color: "black",
    borderRadius: "30px",
    display: "flex",
    columnGap: "15px",
};

export const ContactUs = () => (
    <Grid item xs={12}>
        <SectionHeading sx={{ marginBottom: 0, fontSize: "25px" }}>
            संपर्क करें
        </SectionHeading>
        <div style={{ textAlign: "center" }}>
            <MediaImage
                src={design_heading}
                sx={{
                    width: { xs: 230, md: 275 },
                    height: { xs: 26, md: 35 },
                }}
            />
        </div>

        <Container maxWidth="md" sx={{ pt: 3, pb: 5 }}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "20px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        columnGap: "20px",
                        rowGap: "10px",
                        flexDirection: { xs: "column", sm: "row" },
                        alignItems: "center",
                    }}
                >
                    <MediaImage
                        src={phone_icon}
                        sx={{
                            width: "35px",
                            height: "30px",
                        }}
                    />
                    <Button
                        href="tel:+9196190133227"
                        variant="contained"
                        style={contactBtnStyle}
                    >
                        <LocalPhoneIcon />
                        <Typography
                            component="p"
                            variant="p"
                            className={supportTextStyle}
                        >
                            +91 9619013322
                        </Typography>
                    </Button>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        columnGap: "20px",
                        rowGap: "10px",
                        flexDirection: { xs: "column", sm: "row" },
                        alignItems: "center",
                    }}
                >
                    <MediaImage
                        src={mail_icon}
                        sx={{
                            width: "35px",
                            height: "25px",
                        }}
                    />
                    <Button
                        style={{
                            textTransform: "none",
                        }}
                        color="secondary"
                        href="mailto:info@eonlineworkshop.com"
                    >
                        <Typography
                            component="p"
                            variant="p"
                            color="#066FC7"
                            fontSize="20px"
                            className={supportTextStyle}
                        >
                            info@eonlineworkshop.com
                        </Typography>
                    </Button>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                            component="p"
                            variant="p"
                            fontSize="18px"
                            fontFamily="sans-serif"
                            color="#f57b20"
                        >
                            फॉलो करे @
                        </Typography>
                        <Button
                            variant="text"
                            target="_blank"
                            href="https://www.instagram.com/divine_harmony_with_shreekalp/"
                        >
                            <Box
                                component="img"
                                alt="Instagram"
                                width="32px"
                                height="32px"
                                src={image_instagram}
                            />
                        </Button>
                        <Button
                            variant="text"
                            target="_blank"
                            href="https://www.linkedin.com/company/spiritual-meeting/"
                        >
                            <Box
                                component="img"
                                alt="Instagram"
                                width="32px"
                                height="32px"
                                src={image_linkedin}
                            />
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Container>
    </Grid>
);
